html{
  background-color: #1C67C4;
}
body {
  margin: 0;
  padding: 0;
  font-family: gill-sans-nova, sans-serif;
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
