.App {
  text-align: center;
}
/* FONTS

Didot LT Pro Bold

font-family: linotype-didot, serif;

font-weight: 700;

font-style: normal;


Didot LT Pro Bold Italic

font-family: linotype-didot, serif;

font-weight: 700;

font-style: italic;


Didot LT Pro Italic

font-family: linotype-didot, serif;

font-weight: 400;

font-style: italic;


Didot LT Pro Roman

font-family: linotype-didot, serif;

font-weight: 400;

font-style: normal;
*/


/*
**** Header ****
*/

.App-header {
  margin-top: 3vw;
  margin-bottom: 8vw;
  display: flex;
  font-size: 2vw;
  color: white;
  font-family: gill-sans-nova, sans-serif;
  font-weight: 300;
  font-style: normal;
  -webkit-animation: fade-in 3s forwards;
}

.logo-box{
  float: right;
  width: 50%;
  margin-left: 6vw;
  max-height: 150px;
}

.App-logo {
  /* look up animation css class*/
  height: 15vw;
  width: 12vw;
  margin-right: 0px;
  float: left;
  border-radius: 80px;
}

 .year {
  float: left;
  line-height: 14vw;
  margin: 0;
}

.about-box{
  float: right;
  width: 50%;
  max-height: 100px;
  margin-right: 9.5vw;
}

.about {
  float:right;
  margin-top: 0;
  border: none;
  background:none;
  font-family: gill-sans-nova, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #ffffff;
  font-size: 2.5vw;
  transition: font-size .3s ;

}

.about-content{
  -webkit-animation: fade-in 2.5s forwards;
  -webkit-animation-delay: 0s;
  margin-top: 0;
  border: none;
  background:none;
  font-family: gill-sans-nova, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #ffffff;
  font-size: 2.4vw;
  transition: font-size .3s ;
}

.about-paragraph {
  margin-top: 5.4vw;
  border-bottom: 2px solid #1C67C4;
  transition: border-bottom 1s;
  cursor: pointer;
}

.about p:hover{
  border-bottom: 2px solid #fff;
  transition: border-bottom 1s;
}


.about:focus{
  outline:none;
}

/*
**** Title Box
*/

#title-box {
  margin-left: 12vw;
  text-align: left;
  color: #fff;
}


.title-container {
  font-family: linotype-didot, serif;
  font-weight: 400;
  font-size: 3.3vw;
  line-height: 4vw;
}

/*
*** Generic styles
*/
.bold{
  font-weight:700;
}

.italics{
  font-style: italic;
}

@-webkit-keyframes add-underline{
  from{
    /*font-size: 40px;*/
    border-bottom: .15vw solid #1C67C4;
  }
  to{
    /*font-size: 33px;*/
    border-bottom: .15vw solid #fff;
  }
}

.underlined {
  -webkit-animation: add-underline 1s forwards ;
}
.underlined:hover{
  font-size: 2.58vw !important;
  transition: font-size 5s;
}

.underlined-link{
  border-bottom: .15vw solid #1c67c4;
}

.generic{
  border-bottom: none;
  font-size: 33px;
  transition: font-size 2s border-bottom .3s
}

@-webkit-keyframes remove-underline{
  from{
    border-bottom: .15vw solid #fff;
  }
  to {
    border-bottom: .15vw solid #1C67C4;
  }
}

.remove-underline{
  -webkit-animation: remove-underline 1s;
}



/*
**** Content Selection
*/

.content-selector{
  opacity: 0;
  font-size: 4vw;
  float: left;
  margin-top: 4vw;
  margin-left: 9vw;
  -webkit-animation: fade-in 1.5s forwards;
  -webkit-animation-delay: .8s;
}

.content-selector button{
  font-family: gill-sans-nova, sans-serif;
  font-weight: 300;
  font-style: normal;
  background: none;
  border:none;
  color: #ffffff;
  font-size: 2.58vw;
  margin-right: 1vw;
  transition: font-size .3s;
}

.content-selector
 button:hover{
  font-size: 3vw;
  transition: font-size .4s;
}

.content-selector button:focus{
  outline: none;
}

/*
*** ContentBox
*/

#content-box {
  opacity: 0;
  margin-left: 8.3vw;
  margin-right: 8.3vw;
  margin-top: 10vw;
  -webkit-animation: fade-in 1.5s forwards;
  -webkit-animation-delay: .8s;
}

.ProjectHeader{
  background-image: url("./maatsTitle.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 36vw;
  border-radius: 10px;
  margin-bottom: 2.4vw
}


.ProjectN{
  width: 100%;
  height: 36vw;
  border-radius: 10px;
  margin-bottom: 2.4vw;
  background-image:linear-gradient(to bottom, rgba(30,30,30,.5), rgba(30,30,30,1));
  visibility: hidden;
  opacity: 0;
  transition: opacity .4s, visibility .4s;
  color: #fff;
  text-align: left;
}

.Project1:hover .ProjectN{
  visibility: visible;
  opacity: 1;
}

.Project1{
  width: 100%;
  float:left;
  height: 36vw;
  background-image: url("./NWMH 640.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}

.Project2{
  width:100%;
  height: 36vw;
  float:right;
  background-image: url("./Solidity.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}

.Project2:hover .ProjectN{
  visibility: visible;
  opacity: 1;
}

.Project3{
  width:100%;
  height: 36vw;
  float:left;
  background-image: url("./Risk1 640.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  margin-top: 2.4vw;
}

.Project3:hover .ProjectN{
  visibility: visible;
  opacity: 1;
}

.Project4{
  width:100%;
  height: 36vw;
  float:right;
  background-image: url("./PortfolioV1.0.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  margin-top: 2.4vw;
}

.Project4:hover .ProjectN{
  visibility: visible;
  opacity: 1;
}

.placeholder{
  height: 25vw;
  width: 100%;
  padding-top: 2.5vw;
}

.description-box{
  color: #fff;
  background-image: linear-gradient(to bottom, rgba(30,30,30,.5), rgba(30,30,30,1));
  height: 100.1%;
  width: 100%;
  float: right;
  margin:0;
  border-radius: 10px;
  visibility: hidden;
  opacity: 0;
  transition: opacity .4s, visibility .4s;
  text-align: left;
}

.header-description-text{
  margin: 0 3vw;
  font-size: 2.8vw;
  line-height: 2.5vw;
  vertical-align: text-bottom;
  text-align: center;
}

.description-text{
  margin: 0 3vw;
  font-size: 2.3vw;
  /*line-height: 30px;*/
  vertical-align: text-bottom;
  text-align: center;
}

.description-date{
  font-size: 1.8vw;
  margin: 0 4vw;
  text-align: right;
  color: #fff;
  font-weight: bold;
}

.ProjectHeader:hover .description-box{
  visibility: visible;
  opacity: 1;
}


.ProjectHeader-Link{
  text-decoration: none;
  border: none;
  background-color: inherit;
  margin: 0;
  padding: 0;
  width: 100%;
  font: inherit;
  cursor: pointer;
  outline: none;
}

.Project-Link:nth-child(odd) {
  text-decoration: none;
  border: none;
  background-color: inherit;
  margin: 0px;
  padding: 0;
  width: 48%;
  float: left;
  font:inherit;
  cursor: pointer;
}

.Project-Link:nth-child(even){
  text-decoration: none;
  border: none;
  background-color: inherit;
  margin: 0;
  padding: 0;
  width: 48%;
  float: right;
  font: inherit;
  cursor: pointer;
}



/*
*** articles
*/

.article1{
  background-image: url("./Dimensionless.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 36vw;
  border-radius: 10px;
  margin-bottom: 2.4vw;
}

.article1:hover .description-box{
  visibility: visible;
  opacity: 1;
}

.article2{
  background-image: url("./Medium-Wordmark.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 36vw;
  border-radius: 10px;
  margin-bottom: 2.5vw;
}

.article2:hover .description-box{
  visibility: visible;
  opacity: 1;
}

.article2 .header-description-text{
  line-height:3vw;
}

/*
*** TRAVEL
*/
.travel1{
  background-image: url("./AMSHead.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 39vw;
  border-radius: 10px;
  margin-bottom: 2.5vw
}

.travel1:hover .description-box{
  visibility: visible;
  opacity: 1;
}

.travel2{
  background-image: url("./Travel/Zambia/Zambia-Header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 39vw;
  border-radius: 10px;
  margin-bottom: 2.5vw
}

.travel2:hover .description-box{
  visibility: visible;
  opacity: 1;
}

.travel3{
  background-image: url("./Travel/South Africa/SouthAfrica-Header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 39vw;
  border-radius: 10px;
  margin-bottom: 2.5vw
}

.travel3:hover .description-box{
  visibility: visible;
  opacity: 1;
}

.travel4{
  background-image: url("./Travel/Prague/PragueHeader.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 39vw;
  border-radius: 10px;
  margin-bottom: 2.5vw;
}

.travel4:hover .description-box{
  visibility: visible;
  opacity: 1;
}



/*
*** span render
*/

@-webkit-keyframes fade-in{
  from {
    opacity: 0;
    transform: translateY(15px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@-webkit-keyframes fade-out{
  from{
    opacity: 1;
    transform:translateY(0px)
  }
  to{
    opacity: 0;
    transform:translateY(15px)
  }
}

#span-1{
  opacity: 0;
  -webkit-animation: fade-in 1.5s forwards;
  -webkit-animation-delay: .0s;
}

#span-2{
  opacity: 0;
  -webkit-animation: fade-in 1.5s forwards;
  -webkit-animation-delay: .3s;
}

#span-3{
  opacity: 0;
  -webkit-animation: fade-in 1.5s forwards;
  -webkit-animation-delay: .6s;
}

.transition-out-1{
  opacity:1 !important;
 -webkit-animation: fade-out 1.6s forwards !important;
 -webkit-animation-delay: .9s !important;
}
.transition-out-2{
  opacity: 1 !important;
 -webkit-animation: fade-out 1.3s forwards !important;
 -webkit-animation-delay: .6s !important;
}
.transition-out-3{
  opacity: 1 !important;
 -webkit-animation: fade-out 1s forwards !important;
 -webkit-animation-delay: .3s !important;
}



/*
*** ABOUT ME
*/

#about-me-box{
  height: 35vw;
  text-align: left;
  color: #fff;
  margin-left: 10.8vw;
}

.about-me-text{
  margin-top: 4%;
  font-family: linotype-didot, serif;
  font-weight: 400;
  font-size: 2vw;
  line-height: 2.6vw;
  float: left;
  width: 40%;

}

.about-me-image{
  background-image: url("./aboutMe2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  float: right;
  width: 40%;
  height: 33vw;
  margin-right: 8vw;
  border-radius: 15px;
}


.specifics-box{
  margin-top: 10vw;
}

.line{
  border-bottom: .5vw dotted #fff;
  width: 50%;
  margin: auto;
}

.technology-about{
  width: 100%;
  height: 15vw;
}

.technology-text{
  text-align: left;
  font-size: 2vw;
  line-height: 2.4vw;
  margin-right: 10vw;
  margin-bottom: 10vw;
}



.lego{
  float: left;
  width: 20%;
  height: 10vw;
  background-image: url("./lego.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 14vw;
}

.scuba{
  float: left;
  width: 20%;
  height: 10vw;
  background-image: url("./scuba.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 14vw;
}



.golf-logo{
  float: left;
  width: 18%;
  height: 10vw;
  background-image: url("./golf-logo.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 15px;
  margin-left: 11.3vw;
}

.golf-text{
  text-align: left;
  font-size: 2vw;
  line-height: 2.4vw;
  margin-right: 10vw;
  margin-bottom: 10vw;
  margin-left: 34%;
}

.reach-out-title{
  margin-top: 8vw;
  font-family: linotype-didot, serif;
  font-weight: 700;
  font-style: normal;
  font-size: 5vw;

}

.reach-out-body{
  width: 45%;
  margin: auto;
  line-height: 2.4vw;
  margin-bottom: 5.5vw;
}

.contact-type{
  float: left;
  margin-left: 20%;
  line-height: 4vw;
}

.contact-info{
  float: left;
  margin-left: 4.5vw;
  margin-right: 33%;
  margin-top: auto;
  line-height: 4vw;
}

.email-contact-info{
  float: left;
  margin-left: 5.7vw;
  line-height: 4.5vw;
  margin-right: 33%;
}

.resume-contact-info{
  float: left;
  margin-left: 3.1vw;
  line-height: 4.5vw;
  margin-bottom: 5vw;
}

.resume-link{
  text-decoration: none;
  color: #fff;
  border-bottom: 2px solid #1C67C4;
  transition: border-bottom 1s;
}

.resume-link:hover{
  border-bottom: 2px solid #fff;
  transition: border-bottom 1;
}

.link{
  text-decoration: none;
  color: #000;
}

/*
*** Project Pages Display
*/
.project-content{
  -webkit-animation: fade-in 2.5s forwards;
  margin-top: 0;
  border: none;
  background:none;
  font-family: gill-sans-nova, sans-serif;
  font-weight: 300;
  font-style: normal;
  color: #ffffff;
  font-size: 2.4vw;
  transition: font-size .3s ;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
}

.description-box-live{
  color: #fff;
  background-color:rgb(242, 242, 242,1);
  height: 100%;
  width: 45vw;
  float: right;
  margin:0;
  opacity: 1;
  transition: opacity .4s, visibility .4s;
  text-align: left;
  border-radius: 0 10px 10px 0;
}

.project-image{
  float: left;
  width: 45vw;
  height: 36vw;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 10px 0  0 10px;
}

.box-live{
  width: 100%;
  float: right;
  margin: 0;
  align-items: center;
  height: 36vw;
}

.live-description-text{
  margin:auto;
  width: 80%;
  margin-bottom: 0;
  margin-left: 3vw;
  font-size: 2.6vw;
  line-height: 2.6vw;
  vertical-align: text-bottom;
  text-align: center;
  color: #000;

}

.live-description-text::after{
  border-bottom: 2px solid #1C67C4;
  width: 60%;
  margin: 0 auto;
  padding-top: 1.6vw;
  content: "";
  display: block;
}

.subtitle{
  margin: auto;
  font-size: 1.9vw;
  font-style: normal;
  font-weight: 300;
  margin-top: 1.5vw;
  padding-bottom: .2vw;
  padding-left: 0%;
  padding-right: 0%;
  padding-top: 5px;
  text-transform: uppercase;
  width: 80%;
  clear: both;
  color: #1C67C4;
}
.subtitle-2{
  margin: auto;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 300;
  margin-top: 1.5vw;
  padding-bottom: .2vw;
  padding-left: 0%;
  padding-right: 0%;
  padding-top: .3vw;
  text-transform: uppercase;
  width: 80%;
  clear: both;
  color: #1C67C4;
}

.subtitle-description{
  font-size: 1.6vw;
  line-height: normal;
  font-style: normal;
  width: 80%;
  color: #000;
  margin: auto;
}

.live-placeholder{
    height: 20%;
    width: 100%;
    margin: 0;
}

.description-date-live{
  font-size: 1.6vw;
  margin: 0 4vw;
  text-align: right;
  color: #1C67C4;
  font-weight: bold;
  line-height: 7vw;
}

.ProjectHeader{
  width: 100%;
  height: 36vw;
}

.projectimg-1{
  background-image: url("./maatsTitle.png");
}

.projectimg-2{
  background-image: url("./NWMH 640.jpeg");
}

.projectimg-3{
  background-image: url("./Solidity.png");
}

.projectimg-4{
  background-image: url("./Risk1 640.png");
}

/*
*** Project Body Content
*/

.project-body{
  width: 100%;
  margin-top: 7vw;
}
.picture{
  width: 100%;
  height: auto;
  margin: auto;
  box-shadow: -2px 2px 2px #222222;
  border-radius: 5px;
}

.project-content-left{
  display: block;
  float: left;
  width: 45.2%;
  margin: auto;
  padding-left: 3%;
  height: 33vw;
  object-fit: fill;
  overflow: hidden;
}

.project-content-right{
  float: right;
  width: 50%;
  margin: auto;
  height: 33vw;
}

.project-content-right-2{
  float: right;
  width: 50%;
  margin: auto;
  height: 33vw;
}

.section-title{
  text-transform: uppercase;
  color: rgb(242,242,242);
  font-size: 2vw;
  margin-top: 15%;
  margin-left: 14%;
  text-align: left;
}

.section-title-2{
  text-transform: uppercase;
  color: rgb(242,242,242);
  font-size: 2vw;
  margin-top: 0%;
  margin-left: 14%;
  text-align: left;
}

.section-content{
  color: #fff;
  float: none;
  font-size: 1.6vw;
  font-style: normal;
  font-weight: 350;
  line-height: 2vw;
  margin: auto;
  margin-top: 0px;
  margin-left: 14%;
  padding-top: 4px;
  text-align: left;
  text-transform: none;
  width: 80%;
}


.icon-wrap{
  background-color: #F2F2F2;
  margin: 0px auto 0 auto;
  padding-left: 0%;
  padding-right: 0%;
  padding-bottom: 0;
  padding-top: 0;
  height: 33vw;
  float: right;
  width: 100%;
  border-radius: 10px;
}

.icon-section{
  margin: 2.4vw auto 1.8vw auto;
  padding-right: 3%;
  padding-left: 3%;
  width: 94%;
}

.first-icon{
  width: 31%;
  margin:1% .2% 1% 1.6%;
  float: left;
  display: block;
}

.first-icon:first-child{
  margin-left: 3%;
}

.icon{
  width: 5.9vw;
  height: 5.9vw;
  margin-bottom: .8vw;
  margin-top: .8vw;
  display: block;
}

.long-icon{
  width: 9vw;
  height: 5.15vw;
  margin-bottom: .8vw;
  margin-top: 1.6vw;
  margin-left: 3%;
  display: block;
}

.icon-section-title{
  float: none;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 300;
  margin: auto;
  margin-left: 3%;
  padding-bottom: .2vw;
  padding-left: 0%;
  padding-right: 0%;
  padding-top: 0;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
  clear: both;
  color: #1C67C4;
}

.icon-section .section-content{
  margin-left: 3%;
  color: #000;
}

.big-icon{
  width: 4.8vw;
  height: 4.8vw;
  margin-bottom: 1.8vw;
  margin-top: .9vw;
  display: block;
  margin-left:3%;
}

/*
*** Team section
*/

.team-box{
  padding-right: 0%;
  padding-left: 0%;
  width: 100%;
}

.the-team{
  margin-left: 0;
  width: 48.2%;
  float: left;
  margin: 1% 0 1% 1.6%;
  margin-top: 4.8vw;
}

.team-box .title:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 30%;
    padding-top: 1vw;
    border-bottom: .15vw solid #fff;
}

.team-box .title{
  float: none;
  font-size: 2.6vw;
  font-style: normal;
  font-weight: 300;
  margin: auto;
  padding-bottom: 3px;
  padding-left: 0%;
  padding-right: 0%;
  padding-top: 0px;
  text-align: center;
  text-transform: none;
  width: 100%;
  clear: both;
}

.left-team{
  float: left;
  margin: auto;
  width: 49%;
  align-content: center;
}

.right-team{
  float:right;
  margin: auto;
  width: 49%;
  align-content: center;
}

.team-image{
  margin: 2.4vw auto 1px auto;
  align-content: center;
  text-align: center;
}

.team-image img {
  border-radius: 50%;
  width: 50%;
  align-content: center;
}

.name{
  font-size: 1.7vw;
  margin-top: 0;
}

.my-role-section{
  width: 48.2%;
  float:right;
  margin: 1% 0 1% 1.6%;
  margin-top: 4.8vw;
}

.me-image{
  padding-top: 2vw;
  align-content: center;
  text-align: center;
}

.me-image img {
    border-radius: 50%;
    width: 25%;
    align-content: center;
}

.me-image-1{
  padding-top: 2vw;
  align-content: center;
  text-align: center;
}

.me-image-1 img {
    background-color: #f2f2f2;
    border-radius: 50%;
    width: 25%;
    align-content: center;
}

.my-role{
  display: block;
  width: 85%;
  margin-left: 7%;
  margin-right: 7.4%;
}

.my-role .section-title{
  margin-top: 1.7vw;
}

.my-role .section-content-1{
  color: #fff;
  float: none;
  font-size: 1.7vw;
  font-style: normal;
  font-weight: 350;
  line-height: 1.9vw;
  margin: auto;
  margin-top: 0;
  margin-left: 5%;
  padding-top: .3vw;
  text-align: left;
  text-transform: none;
  width: 100%;
  margin-bottom: 4vw;
}



/*
*** Back to Top button
*/



.arrow{
  background-image: url("./return-arrow.png");
  background-repeat: no-repeat;
  background-size: contain;
  margin: auto;
  width: 2.4vw;
  height: 3.2vw;
  margin-top: 5px;
}

.back-to-top{
  background-color: #f2f2f2;
  border-radius: 50%;
  display: block;
  height: 6.3vw;
  width: 6.3vw;
  margin-left: 70%;
  margin-top: 3vw;
  margin-right: 5%;
  margin-bottom: 30px;
  text-align: center;
  float: right;
  decoration: none;
  border: none;
  transition: transform 1s;
  cursor: pointer;
}

.back-to-top:focus{
  outline: none;
}

.back-to-top:hover{
  transform: translateY(-10px);
  transition: transform 1s;
}



/**
** Travel Page General
**/


.travel-box{
  -webkit-animation: fade-in 2.5s forwards;
  width: 84%;
  margin: auto;
  outline: none;
}

.travel-title{
  margin: auto;
  color: #fff;
  font-size: 4.5vw;
  margin-bottom: 2.4vw;
  font-family: linotype-didot, serif;
}

.travel-image-expand{
  font-size: 4.5vw;
  margin: 0px 4.5vw;
  text-align: right;
  color: #fff;
  font-weight: normal;
  line-height: 9vw;
}

/*
*** Amsterdam specific
*/

.TravelHeader-1{
  background-image: url("./AMSHead.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 39vw;
  border-radius: 10px;
  margin-bottom: 2.4vw;
  cursor: default;
}


.ams-travel{
  width: 100%;
  float:left;
  height: 36vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  cursor: auto;
  margin-bottom: 2.4vw;
}

.ams-image1{
  background-image: url("./Travel/Amsterdam'17/1.jpg");
}

.ams-image2{
  background-image: url("./Travel/Amsterdam'17/2.jpg");
}

.ams-image3{
  background-image: url("./Travel/Amsterdam'17/3.jpg");
}

.ams-image4{
  background-image: url("./Travel/Amsterdam'17/4.jpg");
}

.ams-image5{
  background-image: url("./Travel/Amsterdam'17/5.jpg");
}

.ams-image6{
  background-image: url("./Travel/Amsterdam'17/6.jpg");
}
.ams-image7{
  background-image: url("./Travel/Amsterdam'17/7.jpg");
}

.ams-image8{
  background-image: url("./Travel/Amsterdam'17/8.jpg");
}

.ams-image9{
  background-image: url("./Travel/Amsterdam'17/9.jpg");
}
.ams-image10{
  background-image: url("./Travel/Amsterdam'17/10.jpg");
}
.ams-image11{
  background-image: url("./Travel/Amsterdam'17/11.jpg");
}
.ams-image12{
  background-image: url("./Travel/Amsterdam'17/12.jpg");
}
.ams-image13{
  background-image: url("./Travel/Amsterdam'17/13.jpg");
}
.ams-image14{
  background-image: url("./Travel/Amsterdam'17/14.jpg");
}
.ams-image15{
  background-image: url("./Travel/Amsterdam'17/15.jpg");
}
.ams-image16{
  background-image: url("./Travel/Amsterdam'17/16.jpg");
}
.ams-image17{
  background-image: url("./Travel/Amsterdam'17/17.jpg");
}
.ams-image18{
  background-image: url("./Travel/Amsterdam'17/18.jpg");
}
.ams-image19{
  background-image: url("./Travel/Amsterdam'17/19.jpg");
}
.ams-image20{
  background-image: url("./Travel/Amsterdam'17/20.jpg");
}
.ams-image21{
  background-image: url("./Travel/Amsterdam'17/21.jpg");
}
.ams-image22{
  background-image: url("./Travel/Amsterdam'17/22.jpg");
}
.ams-image23{
  background-image: url("./Travel/Amsterdam'17/23.jpg");
}
.ams-image24{
  background-image: url("./Travel/Amsterdam'17/24.jpg");
}

/*
*** Zambia Specific
*/
.TravelHeader-2{
  background-image: url("./Travel/Zambia/Zambia-Header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 39vw;
  border-radius: 10px;
  margin-bottom: 2.4vw;
  cursor: default;
}

.travelVideo{
  visibility: visible ;
  object-fit: fill;
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin: auto;
  border-radius: 10px;
}


.zam-image1{
  background-image: url("./Travel/Zambia/1.jpg");
}

.zam-image2{
  background-image: url("./Travel/Zambia/2.jpg");
}

.zam-image3{
  background-image: url("./Travel/Zambia/3.jpg");
}

.zam-image4{
  background-image: url("./Travel/Zambia/4.m4v");
}

.zam-image5{
  background-image: url("./Travel/Zambia/5.jpg");
}

.zam-image6{
  background-image: url("./Travel/Zambia/6.jpg");
}
.zam-image7{
  background-image: url("./Travel/Zambia/7.jpg");
}

.zam-image8{
  background-image: url("./Travel/Zambia/8.jpg");
}

.zam-image9{
  background-image: url("./Travel/Zambia/9.jpg");
}
.zam-image10{
  background-image: url("./Travel/Zambia/10.jpg");
}
.zam-image11{
  background-image: url("./Travel/Zambia/11.jpg");
}
.zam-image12{
  background-image: url("./Travel/Zambia/12.jpg");
}
.zam-image13{
  background-image: url("./Travel/Zambia/13.jpg");
}
.zam-image14{
  background-image: url("./Travel/Zambia/14.jpg");
}
.zam-image15{
  background-image: url("./Travel/Zambia/15.jpg");
}
.zam-image16{
  background-image: url("./Travel/Zambia/16.jpg");
}
.zam-image17{
  background-image: url("./Travel/Zambia/17.jpg");
}
.zam-image18{
  background-image: url("./Travel/Zambia/18.jpg");
}
.zam-image19{
  background-image: url("./Travel/Zambia/19.jpg");
}
.zam-image20{
  background-image: url("./Travel/Zambia/20.jpg");
}
.zam-image21{
  background-image: url("./Travel/Zambia/21.jpg");
}
.zam-image22{
  background-image: url("./Travel/Zambia/22.jpg");
}
.zam-image23{
  background-image: url("./Travel/Zambia/23.jpg");
}
.zam-image24{
  background-image: url("./Travel/Zambia/24.jpg");
}
.zam-image25{
  background-image: url("./Travel/Zambia/25.jpg");
}
.zam-image26{
  background-image: url("./Travel/Zambia/26.jpg");
}
.zam-image27{
  background-image: url("./Travel/Zambia/27.jpg");
}
.zam-image28{
  background-image: url("./Travel/Zambia/28.jpg");
}
.zam-image29{
  background-image: url("./Travel/Zambia/29.jpg");
}
.zam-image30{
  background-image: url("./Travel/Zambia/30.jpg");
}


/*
*** South Africa Specific
*/
.TravelHeader-3{
  background-image: url("./Travel/South Africa/SouthAfrica-Header.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 39vw;
  border-radius: 10px;
  margin-bottom: 2.4vw;
  cursor: default;
}



.sou-image1{
  background-image: url("./Travel/South Africa/1.jpg");
}

.sou-image2{
  background-image: url("./Travel/South Africa/2.jpg");
}

.sou-image3{
  background-image: url("./Travel/South Africa/3.jpg");
}

.sou-image4{
  background-image: url("./Travel/South Africa/4.jpg");
}

.sou-image5{
  background-image: url("./Travel/South Africa/5.jpg");
}

.sou-image6{
  background-image: url("./Travel/South Africa/6.jpg");
}
.sou-image7{
  background-image: url("./Travel/South Africa/7.jpg");
}

.sou-image8{
  background-image: url("./Travel/South Africa/8.jpg");
}

.sou-image9{
  background-image: url("./Travel/South Africa/9.jpg");
}
.sou-image10{
  background-image: url("./Travel/South Africa/10.jpg");
}
.sou-image11{
  background-image: url("./Travel/South Africa/11.jpg");
}
.sou-image12{
  background-image: url("./Travel/South Africa/12.jpg");
}
.sou-image13{
  background-image: url("./Travel/South Africa/13.jpg");
}
.sou-image14{
  background-image: url("./Travel/South Africa/14.jpg");
}
.sou-image15{
  background-image: url("./Travel/South Africa/15.jpg");
}
.sou-image16{
  background-image: url("./Travel/South Africa/16.jpg");
}
.sou-image17{
  background-image: url("./Travel/South Africa/17.jpg");
}
.sou-image18{
  background-image: url("./Travel/South Africa/18.jpg");
}
.sou-image19{
  background-image: url("./Travel/South Africa/19.jpg");
}
.sou-image20{
  background-image: url("./Travel/South Africa/20.jpg");
}


/*
*** Prague Specific
*/
.TravelHeader-4{
  background-image: url("./Travel/Prague/PragueHeader.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 39vw;
  border-radius: 10px;
  margin-bottom: 2.4vw;
  cursor: default;
}



.pra-image1{
  background-image: url("./Travel/Prague/1.jpg");
}

.pra-image2{
  background-image: url("./Travel/Prague/2.jpg");
}

.pra-image3{
  background-image: url("./Travel/Prague/3.jpg");
}

.pra-image4{
  background-image: url("./Travel/Prague/4.jpg");
}

.pra-image5{
  background-image: url("./Travel/Prague/5.jpg");
}

.pra-image6{
  background-image: url("./Travel/Prague/6.jpg");
}
.pra-image7{
  background-image: url("./Travel/Prague/7.jpg");
}

.pra-image8{
  background-image: url("./Travel/Prague/8.jpg");
}

.pra-image9{
  background-image: url("./Travel/Prague/9.jpg");
}
.pra-image10{
  background-image: url("./Travel/Prague/10.jpg");
}
.pra-image11{
  background-image: url("./Travel/Prague/11.jpg");
}
.pra-image12{
  background-image: url("./Travel/Prague/12.jpg");
}
.pra-image13{
  background-image: url("./Travel/Prague/13.jpg");
}
.pra-image14{
  background-image: url("./Travel/Prague/14.jpg");
}
.pra-image15{
  background-image: url("./Travel/Prague/15.jpg");
}
